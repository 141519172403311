import {
  faCog,
  faDownload,
  faEye,
  faEyeSlash,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Button, Icon, Popup} from 'semantic-ui-react';
import MediaHelper from '../../utils/mediaHelper';
import {DataActionType, MediaGalleryProps} from './DataConst';

const MediaGallery: React.FunctionComponent<MediaGalleryProps> = (
  props: MediaGalleryProps,
) => {
  return (
    <div
      className={`DataTableContainer ${props.className}`}
      style={props.style}
    >
      <div className={'DataTableHeader'}>
        {props.title && (
          <div className={'DataTableTitleContainer'}>
            {props.titleIcon}
            {typeof props.title === 'string' ? (
              <h1>{props.title}</h1>
            ) : (
              props.title
            )}
          </div>
        )}

        <Button
          className={props.primaryActionClass}
          disabled={props.disabledPrimaryAction}
          icon
          primary
          labelPosition="left"
          onClick={() =>
            props.handleAction(props.primaryAction, null, props.addParams)
          }
        >
          <Icon name="add" />
          {props.primaryActionTitle
            ? props.primaryActionTitle
            : `Add Photos/Videos`}
        </Button>
      </div>

      <div className={'MediaGalleryContainer'}>
        {props.data.length === 0 && (
          <div className={'MediaGalleryNoResultsContainer'}>
            <p>No Photos/Videos found</p>
          </div>
        )}

        {props.data.map((media) => (
          <div
            key={`media-${media.mediaId}`}
            className={'MediaGalleryImageContainer'}
            style={{
              backgroundImage: `url(${MediaHelper.getMediaUrl(media.mediaId)})`,
            }}
          >
            <div>
              <Popup
                content={'Toggle report visibility'}
                trigger={
                  <a
                    onClick={async () => {
                      const updated = {
                        ...props.selectedJob,
                        stages: {
                          ...props.selectedJob.stages,
                          [props.activeStage]: {
                            ...props.selectedJob.stages[props.activeStage],
                            media: props.selectedJob.stages[
                              props.activeStage
                            ].media.map((m) => {
                              if (m.mediaId !== media.mediaId) {
                                return m;
                              }
                              return {
                                ...m,
                                displayInReports:
                                  media.displayInReports === false,
                              };
                            }),
                          },
                        },
                      };

                      await props.setSelectedJob(updated);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={
                        media.displayInReports === false ? faEyeSlash : faEye
                      }
                    />
                  </a>
                }
              />
              <Popup
                content={'Edit Photo/Video'}
                trigger={
                  <a
                    onClick={() =>
                      props.handleAction(
                        DataActionType.EDIT_MEDIA,
                        media.mediaId,
                        {
                          stage: props.addParams.stage,
                        },
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faCog} />
                  </a>
                }
              />
            </div>
            <div>
              <Popup
                content={'Delete Photo/Video'}
                trigger={
                  <a
                    className={'DeleteMedia'}
                    onClick={() =>
                      props.handleAction(
                        DataActionType.DELETE_MEDIA,
                        media.mediaId,
                        {
                          stage: props.addParams.stage,
                        },
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </a>
                }
              />

              <Popup
                content={'Download Photo/Video'}
                trigger={
                  <a
                    href={MediaHelper.getMediaUrl(media.mediaId)}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                }
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MediaGallery;
